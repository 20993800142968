import { gql } from '@thriveglobal/thrive-web-core'

export const WRITE_REFERRAL = gql`
    mutation WriteReferral($referringUserId: UUID!) {
        identity {
            user {
                writeReferral(referringUserId: $referringUserId)
            }
        }
    }
`
