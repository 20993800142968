import React, { useEffect } from 'react'
import { captureMessage } from '@thriveglobal/thrive-web-core'

interface IRenderNothingForSecondsProps {
    secondsToWait: number
    onTimeout: Function
}

export const RenderNothingForSeconds = ({
    secondsToWait,
    onTimeout
}: IRenderNothingForSecondsProps) => {
    useEffect(() => {
        const waitTime = secondsToWait * 1000
        const timeoutId = setTimeout(() => {
            captureMessage(
                `sign in - Nothing was rendered for ${secondsToWait} seconds, triggering timeout callback`
            )
            onTimeout()
        }, waitTime)

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId)
            }
        }
    }, [])
    return <React.Fragment />
}

export default RenderNothingForSeconds
