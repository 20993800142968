import { gql } from '@thriveglobal/thrive-web-core'

export const SAVE_USER_ATTRIBUTES = gql`
    mutation Save($attributes: [UserAttributeInput!]!) {
        identity {
            userAttributes {
                save(attributes: $attributes) {
                    name
                    userId
                    value
                }
            }
        }
    }
`

export const SET_USER_TNC_ACCEPTANCE_TIMESTAMP = gql`
    mutation SetUserTncAcceptanceTimestamp {
        identity {
            user {
                acceptToS {
                    userId
                    serverTime
                    userTime
                    userZoneId
                }
            }
        }
    }
`
