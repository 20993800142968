import { type ThriveAppPlatform } from '@thriveglobal/thrive-web-core'
import { CallsPlatform } from '../callsAdapter'

export enum KeycloakClient {
    ANDROID = 'frontend-android',
    IOS = 'frontend-ios',
    MS_TEAMS = 'frontend-ms-teams',
    WEB = 'frontend-web',
    WEBEX = 'frontend-webex',
    WEBEX_TST = 'frontend-webex-tst',
    NICE = 'frontend-nice',
    GENESYS = 'frontend-genesys',
    DEFAULT = 'frontend'
}

type PlatformMapType = {
    [key in ThriveAppPlatform]: KeycloakClient
}
const platformMap = {
    'hybrid-android': KeycloakClient.ANDROID,
    'hybrid-ios': KeycloakClient.IOS,
    msteams: KeycloakClient.MS_TEAMS,
    web: KeycloakClient.WEB,
    webex: KeycloakClient.WEBEX
}

const callsPlatformMap = {
    webex: KeycloakClient.WEBEX_TST,
    nice: KeycloakClient.NICE,
    genesys: KeycloakClient.GENESYS
}

function getClientForPlatform(
    platform: ThriveAppPlatform,
    callsPlatform?: CallsPlatform
): KeycloakClient {
    let client = null
    if (callsPlatform) {
        client = callsPlatformMap[callsPlatform]
    } else {
        client = (platformMap as PlatformMapType)[platform] as KeycloakClient
    }
    if (!client) {
        return KeycloakClient.DEFAULT
    }
    return client
}

export default getClientForPlatform
