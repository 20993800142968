import { AuthState } from '@thriveglobal/thrive-web-core'
import jwt_decode from 'jwt-decode'

export enum AuthType {
    azuread = 'azuread',
    identity = 'identity',
    identity_sso = 'identity_sso',
    identity_internal = 'identity_internal',
    calls = 'calls',
    magicLink = 'magiclink',
    webex = 'webex',
    unknown = 'unknown'
}

export enum LicenseValues {
    standard = 'standard',
    thriveReset = 'thriveResetOnly'
}

type LicenseValuesType = `${LicenseValues}`

export enum SubscriptionTypeValues {
    employee = 'employee',
    friendsAndFamily = 'friendsAndFamily',
    other = 'other'
}

type SubscriptionTypeValuesType = `${SubscriptionTypeValues}`

export const selectIsTokenExpired = (state: AuthState) => {
    return Math.floor(Date.now() / 1000) > state.tokenExpiration
}

export const selectIsAccessTokenExpired = (state: AuthState) => {
    return Math.floor(Date.now() / 1000) > state.accessTokenExpiration
}

export const selectIsAccessTokenExpiringSoon = (state: AuthState) => {
    const fiveMinutes = 60 * 5
    const currentTime = Math.floor(Date.now() / 1000)
    const fiveMinutesFromNow = currentTime + fiveMinutes
    return state.accessTokenExpiration < fiveMinutesFromNow
}

export const selectIsRefreshTokenExpired = (state: AuthState) => {
    return Math.floor(Date.now() / 1000) > state.refreshTokenExpiration
}

export function selectIsServerSessionEnded(authState: AuthState): boolean {
    const authType = authState.authType
    if (!authType || !authState.serverSessionExpired) {
        return false
    }
    return authState.serverSessionExpired
}

export function selectIsLoggedIn(authState: AuthState): boolean {
    if (!authState) {
        return false
    }
    return Boolean(authState.isLoggedIn) && !!authState.accessToken
}

export function selectIsUsingIdentityAuthAndTokensStillValid(
    authState: AuthState
): boolean {
    const {
        isServerSessionEnded,
        isAccessTokenExpired,
        isRefreshTokenExpired
    } = selectAreTokensStillValidReason(authState)
    const isTokenStillValid =
        (!isRefreshTokenExpired || !isAccessTokenExpired) &&
        !isServerSessionEnded
    return isTokenStillValid
}

export function selectAreTokensStillValidReason(authState: AuthState) {
    const isServerSessionEnded = selectIsServerSessionEnded(authState)
    const isAccessTokenExpired = selectIsAccessTokenExpired(authState)
    const isRefreshTokenExpired = selectIsRefreshTokenExpired(authState)
    return {
        isServerSessionEnded,
        isAccessTokenExpired,
        isRefreshTokenExpired
    }
}

export function selectIsTokenBeingRefreshed(authState: AuthState): boolean {
    if (!authState) {
        return false
    }
    return authState.tokenRefreshInProgress
}

export function selectSubscriptionType(authState: AuthState): string {
    if (!authState) {
        return null
    }
    return authState.subscriptionType
}

export function selectSubscriptionId(authState: AuthState): string {
    if (!authState) {
        return null
    }
    return authState.subscriptionId
}

export function selectLicense(authState: AuthState): string {
    if (!authState) {
        return null
    }
    return authState.license
}

export function selectHasLicense(
    authState: AuthState,
    licenseToCheck: LicenseValuesType
): boolean {
    const license = selectLicense(authState)
    return license === licenseToCheck
}

export function selectHasSubscriptionType(
    authState: AuthState,
    subscriptionToCheck: SubscriptionTypeValuesType
): boolean {
    const subscriptionType = selectSubscriptionType(authState)
    return subscriptionType === subscriptionToCheck
}

export function hasTroLicense(authState: AuthState): boolean {
    return selectHasLicense(authState, 'thriveResetOnly')
}

export function hasStandardLicense(authState: AuthState): boolean {
    return selectHasLicense(authState, 'standard')
}
