import { GET_LOCALE_TIMEZONE } from '../../graphql/queries/profile'
import { UPDATE_LOCALE_TIMEZONE } from '../../graphql/mutations/profile'
import {
    captureException,
    getApolloClient,
    WebApplicationLocale,
    getBestFitLocale,
    setLocale,
    setTimezone,
    store
} from '@thriveglobal/thrive-web-core'
import { IdentityUserAttribute } from 'src/graphql/generated/autogenerated'

export interface UserLocaleTimezone {
    locale: WebApplicationLocale | null
    timezone: string | null
}

export async function getUserLocaleTimezone(): Promise<
    UserLocaleTimezone | never
> {
    try {
        const client = getApolloClient()
        const result = await client.query({
            query: GET_LOCALE_TIMEZONE
        })
        const attributes = result?.data?.identity?.userAttributes?.get
        const locale = attributes?.find(
            (attr: IdentityUserAttribute) => attr.name === 'locale'
        )
        const timezone = attributes?.find(
            (attr: IdentityUserAttribute) => attr.name === 'timeZone'
        )
        return {
            locale: locale?.value || null,
            timezone: timezone?.value || null
        }
    } catch (error) {
        captureException(error as Error, {
            message: `Unable to get user's locale and timezone.`
        })
        throw error
    }
}

export async function updateUserLocaleTimezone(
    locale: WebApplicationLocale,
    timezone: string
) {
    try {
        const client = getApolloClient()
        const result = await client.mutate({
            variables: {
                locale: locale,
                timeZone: timezone
            },
            mutation: UPDATE_LOCALE_TIMEZONE
        })
        const attributes = result?.data?.identity?.userAttributes?.update
        const updatedLocale = attributes?.find(
            (attr: IdentityUserAttribute) => attr.name === 'locale'
        )
        const updatedTimezone = attributes?.find(
            (attr: IdentityUserAttribute) => attr.name === 'timeZone'
        )
        //update store
        updatedLocale && store.dispatch(setLocale(updatedLocale.value))
        updatedTimezone && store.dispatch(setTimezone(updatedTimezone.value))
    } catch (error) {
        captureException(error as Error, {
            message: `Unable to update user's locale and timezone.`
        })
        throw error
    }
}

export async function setUserLocaleTimezone() {
    const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const defaultLocale = getBestFitLocale()
    try {
        const savedLocaleTimezone = await getUserLocaleTimezone()
        if (savedLocaleTimezone.locale && savedLocaleTimezone.timezone) {
            store.dispatch(setLocale(savedLocaleTimezone.locale))
            store.dispatch(setTimezone(savedLocaleTimezone.timezone))
        } else {
            //user has no saved locale/timezone so save the defaults in db
            await updateUserLocaleTimezone(defaultLocale, defaultTimezone)
        }
    } catch (error) {
        //user defaults
        store.dispatch(setLocale(defaultLocale))
        store.dispatch(setTimezone(defaultTimezone))
    }
}
