import {
    captureException,
    store,
    authSetTokenRefreshInProgress
} from '@thriveglobal/thrive-web-core'
import { AuthType } from '../../store/selectors/auth'
import refreshAccessToken from './refreshAccessToken'
import getRefreshStateValues from './getRefreshStateValues'

function setIsTokenBeingRefreshed(newValue: boolean) {
    store.dispatch(authSetTokenRefreshInProgress(newValue))
}

export async function maybeRefreshToken() {
    try {
        // Clear token refresh flag as could be left over from a previous call that never resolved (eg. User closing browser)
        setIsTokenBeingRefreshed(false)
        const {
            authType,
            isLoggedIn,
            isAccessTokenExpiredOrWillSoon,
            isRefreshTokenExpired,
            isServerSessionEnded
        } = getRefreshStateValues()

        if (authType === AuthType.identity_internal) {
            return
        }
        if (isLoggedIn) {
            await refreshAccessToken({
                isAccessTokenExpiredOrWillSoon,
                isRefreshTokenExpired,
                isServerSessionEnded,
                setIsTokenBeingRefreshed
            })
        }
    } catch (error) {
        captureException(error, {
            message: 'maybeRefreshToken failed unexpectedly'
        })
        setIsTokenBeingRefreshed(false)
    }
}

export default maybeRefreshToken
