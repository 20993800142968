import { createOidcClient } from './oidcClientFactory'
import getStatusFromFailedResponse from '../getStatusFromFailedResponse'
import { TokenType } from '../shared'

const getIdentityProviderUrl = () => process.env.KEYCLOAK_IDENTITY_PROVIDER
export const keycloakOidcBase = `${getIdentityProviderUrl()}/realms/ThriveGlobal/protocol/openid-connect`
export const keycloakSignUpBase = `${getIdentityProviderUrl()}/realms/ThriveGlobal/identity-public`

const { signIn, revoke, getLogoutUrl, refresh } = createOidcClient(
    keycloakOidcBase,
    'keycloak'
)

interface IdentitySignUpPayload {
    email: string
    password: string
    firstName: string
    lastName: string
    groupCode: string
    attributes?: any
}

async function tokenExchange(token: string, tokenType: TokenType) {
    const response = await fetch(`${keycloakOidcBase}/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            client_id: 'frontend',
            subject_token: token,
            grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
            subject_token_type: `urn:ietf:params:oauth:token-type:${tokenType}`
        })
    })

    if (!response.ok) {
        const errorStatus = await getStatusFromFailedResponse(response)
        const error = new Error(
            `Error calling keycloak token exchange ${tokenType}. [code]: ${errorStatus.code} [message]: ${errorStatus.message}`
        )
        throw error
    }
    return response.json()
}

interface SignatureHeaders {
    message: string
    signature: string
}

async function getSignatureHeaders(): Promise<SignatureHeaders> {
    const response = await fetch(`${keycloakSignUpBase}/signature`)
    if (!response.ok) {
        const errorStatus = await getStatusFromFailedResponse(response)
        const error = new Error(
            `Error getting signature headers. [code]: ${errorStatus.code} [message]: ${errorStatus.message}`
        )
        throw error
    }
    return response.json()
}

export const ThriveIdentityAuth = {
    signIn,
    revoke,
    getLogoutUrl,
    tokenExchange,
    refresh,
    signUp: async ({
        email,
        password,
        firstName,
        lastName,
        groupCode,
        attributes
    }: IdentitySignUpPayload) => {
        const signatureHeaders = await getSignatureHeaders()
        const response = await fetch(`${keycloakSignUpBase}/users`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'X-IDENTITY-SIGNATURE': signatureHeaders.signature,
                'X-IDENTITY-SIGNATURE-MESSAGE': signatureHeaders.message
            },
            body: JSON.stringify({
                email,
                password,
                firstName,
                lastName,
                signupCode: groupCode,
                ...(attributes && { attributes })
            })
        })
        if (!response.ok) {
            const errorStatus = await getStatusFromFailedResponse(response)
            const error = new Error(
                `Error calling keycloak sign up. [code]: ${errorStatus.code} [message]: ${errorStatus.message}`
            )
            if (errorStatus.code === 409) {
                error.name = 'UsernameExistsException'
            }
            throw error
        }
        return response.json()
    }
}
