interface ErrorResponseStatus {
    code: string | number
    message: string
}

async function getStatusFromFailedResponse(
    response: Response
): Promise<ErrorResponseStatus> {
    // We need to clone response as it can only be used once and will cause code elsewhere to fail if consumed here
    const responseCopy = response.clone()
    // Sometimes we will get JSON from a failed response, sometimes we don't.
    try {
        const jsonResponse = await responseCopy.json()
        return {
            code:
                jsonResponse.code ||
                jsonResponse?.error ||
                responseCopy?.status,
            message:
                jsonResponse.message ||
                jsonResponse?.error_description ||
                responseCopy?.statusText
        }
    } catch {
        return {
            code: responseCopy?.status,
            message: responseCopy?.statusText
        }
    }
}

export default getStatusFromFailedResponse
