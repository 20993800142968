import { captureException, store, Apollo } from '@thriveglobal/thrive-web-core'
import { WRITE_REFERRAL } from '../../../graphql/mutations/identity/writeReferral'
import { Avo } from '@thriveglobal/thrive-web-tracking'

export async function creditReferrer(referrerId: string, token: string) {
    let wroteReferral
    const client = new Apollo.ApolloClient({
        uri: `${process.env.APOLLO_GATEWAY}`,
        cache: new Apollo.InMemoryCache(),
        headers: {
            authorization: `Bearer ${token}`
        }
    })

    try {
        await client.mutate({
            mutation: WRITE_REFERRAL,
            variables: { referringUserId: referrerId }
        })
        wroteReferral = true
    } catch (error) {
        captureException(error as Error, {
            message: `Unable to write referral for: ${referrerId}`
        })
    }

    if (wroteReferral) {
        try {
            if (referrerId) {
                Avo.signupCompleted({
                    activityType: 'referral_signup_completed',
                    featureType: 'signup',
                    companyId: store.getState().user.companyId,
                    method: 'credentials',
                    reason: null
                })
            }

            globalThis.sessionStorage.removeItem('referrerId')
            globalThis.sessionStorage.removeItem('companyName')
        } catch (error) {
            console.error(error)
        }
    }
}
