import getStatusFromFailedResponse from '../getStatusFromFailedResponse'

const getIdentityProviderUrl = () => process.env.KEYCLOAK_IDENTITY_PROVIDER
export const keycloakTokenExchange = (realm: string) =>
    `${getIdentityProviderUrl()}/realms/${realm}/protocol/openid-connect/token`

async function getTokenWithPermissions(
    accessToken: string,
    realm: 'master' | 'ThriveGlobal'
) {
    const url = keycloakTokenExchange(realm)
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            grant_type: 'urn:ietf:params:oauth:grant-type:uma-ticket',
            audience: 'thrive-access'
        })
    })

    if (!response.ok) {
        const errorStatus = await getStatusFromFailedResponse(response)
        const error = new Error(
            `Error calling keycloak token exchange. [code]: ${errorStatus.code} [message]: ${errorStatus.message}`
        )
        throw error
    }
    return response.json()
}

export default getTokenWithPermissions
