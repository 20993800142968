import React, { useCallback, useEffect, useState } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { isLoginOrLogoutUrl, LoginRedirect } from './LoginRedirect'
import {
    saveState,
    store,
    isRunningInWebview,
    postMessageToWebview,
    HybridEventType,
    resetAuthState,
    useAppSelector,
    captureMessage
} from '@thriveglobal/thrive-web-core'
import {
    selectIsTokenBeingRefreshed,
    selectIsUsingIdentityAuthAndTokensStillValid,
    selectAreTokensStillValidReason
} from '../../../store/selectors/auth'
import RenderNothingForSeconds from './RenderNothingForSeconds'

export const REDIRECT_URI = 'REDIRECT_URI'
const TIMEOUT_WAIT_SECONDS = 3
export function setRedirectUrlForProtectedRoute(currentPath: string) {
    if (window.location.pathname.indexOf('/ms-teams') === 0) {
        saveState(
            REDIRECT_URI,
            `/ms-teams/auth?relay=${currentPath.substring(1)}`
        )
    } else if (!isLoginOrLogoutUrl(currentPath)) {
        saveState(REDIRECT_URI, window.location.href)
    }
}

interface IProtectedRoute {
    component: React.ComponentType
}

type ProtectedRouteProps = IProtectedRoute & RouteProps

export const ProtectedRoute = ({
    component: Component,
    ...rest
}: ProtectedRouteProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [refreshTimeout, setRefreshTimeout] = useState<boolean>(false)
    const [isValidLogin, setIsValidLogin] = useState<boolean>(false)
    const authStore = useAppSelector((state) => state.auth)
    const isTokenBeingRefreshed: boolean =
        selectIsTokenBeingRefreshed(authStore)

    useEffect(() => {
        if (isTokenBeingRefreshed && !refreshTimeout) {
            return
        }
        const currentPath = window.location.pathname
        const authStore = store.getState().auth
        const loginValid =
            selectIsUsingIdentityAuthAndTokensStillValid(authStore)
        setIsValidLogin(loginValid)
        if (!loginValid && !isLoginOrLogoutUrl(currentPath)) {
            // Wiping auth state from redux store
            const loginInvalidReason =
                selectAreTokensStillValidReason(authStore)
            captureMessage(
                `Login not valid, resetting auth state, and clearing storage. Login invalid reason: ${JSON.stringify(
                    loginInvalidReason
                )}`
            )
            store.dispatch(resetAuthState())
            localStorage.clear()
            if (isRunningInWebview()) {
                postMessageToWebview({ type: HybridEventType.LOGOUT })
            }
        }
        setRedirectUrlForProtectedRoute(currentPath)
        setIsLoading(false)
    }, [isTokenBeingRefreshed, refreshTimeout])

    const onLoadingTimeout = useCallback(
        () => setRefreshTimeout(true),
        [setRefreshTimeout]
    )

    return (
        <React.Fragment>
            <Route
                {...rest}
                render={(props: any) => {
                    if (isLoading) {
                        return (
                            <RenderNothingForSeconds
                                secondsToWait={TIMEOUT_WAIT_SECONDS}
                                onTimeout={onLoadingTimeout}
                            />
                        )
                    }
                    return isValidLogin ? (
                        <Component {...props} />
                    ) : (
                        <LoginRedirect />
                    )
                }}
            />
        </React.Fragment>
    )
}

export const KeyCloakProtectedRoute = ({
    component: Component,
    ...rest
}: ProtectedRouteProps) => {
    const [validLogin, setValidLogin] = React.useState(
        !!store.getState().auth || false
    )
    store.subscribe(async () => {
        const authStore = store.getState().auth
        const validLogin = authStore.isLoggedIn
        if (validLogin) {
            setValidLogin(validLogin)
        }
    })
    return (
        <React.Fragment>
            <Route
                {...rest}
                render={(props: any) =>
                    validLogin ? <Component {...props} /> : null
                }
            />
        </React.Fragment>
    )
}
