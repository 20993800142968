import { useEffect, useState } from 'react'
import { AuthType } from '../../store/selectors/auth'
import getRefreshStateValues from '../../utils/maybeRefreshToken/getRefreshStateValues'
import refreshAccessToken from '../../utils/maybeRefreshToken/refreshAccessToken'

// Deprecated soon...
export function useMaybeRefreshToken(): boolean {
    const [isTokenBeingRefreshed, setIsTokenBeingRefreshed] =
        useState<boolean>(false)
    const {
        authType,
        isLoggedIn,
        isAccessTokenExpiredOrWillSoon,
        isRefreshTokenExpired,
        isServerSessionEnded
    } = getRefreshStateValues()

    useEffect(() => {
        // Token refresh is not supported using internal auth
        if (authType === AuthType.identity_internal) {
            return
        }
        refreshAccessToken({
            isAccessTokenExpiredOrWillSoon,
            isRefreshTokenExpired,
            isServerSessionEnded,
            setIsTokenBeingRefreshed
        })
    }, [
        isLoggedIn,
        authType,
        isAccessTokenExpiredOrWillSoon,
        isRefreshTokenExpired,
        isServerSessionEnded
    ])

    return isTokenBeingRefreshed
}

export default useMaybeRefreshToken
