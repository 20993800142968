import { SET_USER_TNC_ACCEPTANCE_TIMESTAMP } from '../../../graphql/mutations/identity/userAttributes'
import {
    captureException,
    getApolloClient
} from '@thriveglobal/thrive-web-core'

export async function saveTncAcceptanceTimestamp() {
    try {
        const client = getApolloClient()
        await client.mutate({
            mutation: SET_USER_TNC_ACCEPTANCE_TIMESTAMP
        })
    } catch (error) {
        captureException(error as Error, {
            message: `Unable to set terms and conditions acceptance timestamp.`
        })
        throw error
    }
}
