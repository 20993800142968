import { updateStore } from '../../shared'
import {
    store,
    authSetIssuer,
    authSetLastLoggedIn,
    authSetRefreshToken,
    authSetRefreshTokenExpiration,
    authSetThriveToken,
    userSetAnalyticsId,
    authSetSubscriptionId,
    authSetSubscriptionType,
    authSetLicense,
    userSetFirstName,
    userSetLastName
} from '@thriveglobal/thrive-web-core'
import jwt_decode from 'jwt-decode'

interface LoadTokenIntoStoreParams {
    accessToken: string
    idToken?: string // If user signed in via token exchange we won't have an id token
    refreshToken: string
    email?: string
}

function loadTokenIntoStore({
    accessToken,
    idToken,
    refreshToken,
    email
}: LoadTokenIntoStoreParams) {
    const decodedIdToken: any =
        typeof idToken === 'string' ? jwt_decode(idToken) : {}
    const decodedAccessToken: any = jwt_decode(accessToken)
    const decodedRefreshToken: any = jwt_decode(refreshToken)
    const name = decodedAccessToken.name || ''
    const id = decodedAccessToken.sub
    const companyId = decodedAccessToken.companyId
    const idTokenEmail = decodedIdToken.email
    updateStore({
        email: email || idTokenEmail,
        name,
        accessToken,
        userId: id,
        tokenExpiration: decodedIdToken.exp,
        accessTokenExpiration: decodedAccessToken.exp,
        companyId,
        idToken
    })
    store.dispatch(userSetFirstName(decodedAccessToken.given_name))
    store.dispatch(userSetLastName(decodedAccessToken.family_name))
    store.dispatch(authSetRefreshToken(refreshToken))
    store.dispatch(authSetThriveToken(accessToken))
    store.dispatch(authSetRefreshTokenExpiration(decodedRefreshToken.exp))
    store.dispatch(userSetAnalyticsId(id))
    store.dispatch(authSetLastLoggedIn(decodedAccessToken.iat))
    store.dispatch(authSetIssuer(decodedAccessToken.iss))
    store.dispatch(authSetSubscriptionId(decodedAccessToken.subscriptionId))
    store.dispatch(authSetSubscriptionType(decodedAccessToken.subscriptionType))
    store.dispatch(authSetLicense(decodedAccessToken.license))
    return { id }
}

export default loadTokenIntoStore
