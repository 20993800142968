import { captureException } from '@thriveglobal/thrive-web-core'
import auth from '../../utils/Auth'

interface IRefreshAccessToken {
    isAccessTokenExpiredOrWillSoon: boolean
    isRefreshTokenExpired: boolean
    isServerSessionEnded: boolean
    setIsTokenBeingRefreshed?: Function
}

export async function refreshAccessToken({
    isAccessTokenExpiredOrWillSoon,
    isRefreshTokenExpired,
    isServerSessionEnded,
    setIsTokenBeingRefreshed
}: IRefreshAccessToken) {
    if (isRefreshTokenExpired || isServerSessionEnded) {
        return
    }
    if (isAccessTokenExpiredOrWillSoon) {
        setIsTokenBeingRefreshed?.(true)
        try {
            await auth.refreshToken()
        } catch (error) {
            captureException(
                error,
                {
                    message: 'Failed to refresh access token'
                },
                'sign-in'
            )
        }
        setIsTokenBeingRefreshed?.(false)
    }
}

export default refreshAccessToken
