import getStatusFromFailedResponse from '../getStatusFromFailedResponse'
export type TokenType = 'access_token' | 'refresh_token'

const EXPIRED_SESSION_ERROR_NAME = 'ExpiredSessionError'
const INVALID_GRANT_CODE = 'invalid_grant'
const EXPIRED_SESSION_MESSAGE = 'Session not active'
const USER_DISABLED_MESSAGE = 'User disabled'
const SESSION_CANT_BE_FOUND = "Session doesn't have required client"

type identityNameType = 'keycloak' // Used to include okta

function createSignIn(baseUrl: string, identityName: identityNameType) {
    return async (username: string, password: string, clientId: string) => {
        const response = await fetch(`${baseUrl}/token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json'
            },
            body: new URLSearchParams({
                client_id: clientId,
                grant_type: 'password',
                scope: 'openid',
                username: username,
                password: password
            })
        })
        if (!response.ok) {
            const errorStatus = await getStatusFromFailedResponse(response)
            const error = new Error(
                `Error calling ${identityName} login. [code]: ${errorStatus.code} [message]: ${errorStatus.message}`
            )
            if (
                (errorStatus.code === 'invalid_grant' &&
                    errorStatus.message === 'Invalid user credentials') ||
                response?.status === 401
            ) {
                error.name = 'NotAuthorizedException'
            }
            throw error
        }
        return response.json()
    }
}

function createRevoke(baseUrl: string, identityName: identityNameType) {
    return async (token: string, clientId: string, tokenType?: TokenType) => {
        const response = await fetch(`${baseUrl}/revoke`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json'
            },
            body: new URLSearchParams({
                client_id: clientId,
                token,
                ...(tokenType && { token_type_hint: tokenType })
            })
        })
        if (!response.ok) {
            const errorStatus = await getStatusFromFailedResponse(response)
            throw new Error(
                `Error calling ${identityName} revoke. [code]: ${errorStatus.code} [message]: ${errorStatus.message} [clientId]: ${clientId}`
            )
        }
    }
}

function createGetLogoutUrl(baseUrl: string) {
    return (token: string, clientId: string, postLogoutUrl: string = null) => {
        // Since we dont have the id token we can just redirect to our own logout url
        if (!token) {
            return postLogoutUrl || `${window.location.origin}/logout`
        }
        const search = new URLSearchParams({
            client_id: clientId,
            id_token_hint: token,
            post_logout_redirect_uri:
                postLogoutUrl || `${window.location.origin}/logout`
        })
        return `${baseUrl}/logout?${search.toString()}`
    }
}

function createRefreshToken(baseUrl: string, identityName: identityNameType) {
    return async (token: string, clientId: string) => {
        const response = await fetch(`${baseUrl}/token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json'
            },
            body: new URLSearchParams({
                client_id: clientId,
                grant_type: 'refresh_token',
                refresh_token: token
            })
        })
        if (!response.ok) {
            const errorStatus = await getStatusFromFailedResponse(response)
            if (
                errorStatus.code === INVALID_GRANT_CODE &&
                (errorStatus.message === EXPIRED_SESSION_MESSAGE ||
                    errorStatus.message === USER_DISABLED_MESSAGE ||
                    errorStatus.message === SESSION_CANT_BE_FOUND)
            ) {
                const ExpiredSessionError = new Error(
                    `Session with ${identityName} has ended`
                )
                ExpiredSessionError.name = EXPIRED_SESSION_ERROR_NAME
                throw ExpiredSessionError
            }

            throw new Error(
                `Error calling ${identityName} token refresh. [code]: ${errorStatus.code} [message]: ${errorStatus.message} [clientId]: ${clientId}`
            )
        }
        return response.json()
    }
}

export function createOidcClient(
    baseUrl: string,
    identityName: identityNameType
) {
    return {
        signIn: createSignIn(baseUrl, identityName),
        revoke: createRevoke(baseUrl, identityName),
        refresh: createRefreshToken(baseUrl, identityName),
        getLogoutUrl: createGetLogoutUrl(baseUrl)
    }
}
