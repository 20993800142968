import { captureException } from '@thriveglobal/thrive-web-core'

const getEmailVerificationUrl = (): string =>
    process.env.EMAIL_VERIFICATION_URL as string

export interface VerifyEmailRequestBody {
    userId: string
    redirectUrl?: string
    clientId?: string
    expirationForLinkInMinutes?: number
    companyName?: string
}

export const enum SendVerificationEmailResultType {
    Success = 'success',
    Error = 'error'
}

export interface SendVerificationEmailResult {
    status: SendVerificationEmailResultType
    error?: string
}

export const sendVerificationEmail = async (
    body: VerifyEmailRequestBody
): Promise<SendVerificationEmailResult> => {
    try {
        const resp = await fetch(getEmailVerificationUrl(), {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                accept: '*/*'
            },
            body: JSON.stringify({ ...body })
        })
        if (!resp.ok) {
            const error = new Error('Error sending verification email')
            captureException(
                error,
                { message: `Status: ${resp.status}` },
                'identity'
            )
            return {
                status: SendVerificationEmailResultType.Error
            }
        }
        return {
            status: SendVerificationEmailResultType.Success
        }
    } catch (error) {
        captureException(
            error,
            { message: 'Unknown Error sending verification email.' },
            'identity'
        )
        return {
            status: SendVerificationEmailResultType.Error,
            error: error.message
        }
    }
}
