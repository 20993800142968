import { store } from '@thriveglobal/thrive-web-core'
import {
    selectIsTokenExpired,
    selectIsServerSessionEnded,
    selectIsLoggedIn,
    selectIsAccessTokenExpired,
    selectIsRefreshTokenExpired,
    selectIsAccessTokenExpiringSoon,
    AuthType
} from '../../store/selectors/auth'

interface IRefreshStateValues {
    authType: AuthType
    isLoggedIn: boolean
    isAccessTokenExpiredOrWillSoon: boolean
    isRefreshTokenExpired: boolean
    isServerSessionEnded: boolean
}

export function getRefreshStateValues(): IRefreshStateValues {
    const authStore = store.getState().auth
    const authType = authStore.authType as unknown as AuthType

    const isLoggedIn: boolean = selectIsLoggedIn(authStore)
    const isAccessTokenExpiredOrWillSoon =
        selectIsAccessTokenExpired(authStore) ||
        selectIsAccessTokenExpiringSoon(authStore)
    const isRefreshTokenExpired = selectIsRefreshTokenExpired(authStore)
    const isServerSessionEnded = selectIsServerSessionEnded(authStore)
    return {
        authType,
        isLoggedIn,
        isAccessTokenExpiredOrWillSoon,
        isRefreshTokenExpired,
        isServerSessionEnded
    }
}

export default getRefreshStateValues
