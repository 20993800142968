import { useLocation } from 'react-router-dom'

interface LocationState {
    referrer?: string
    from?: string
}

export const useHasReferrer = (referrer: string | string[]) => {
    const { state } = useLocation<LocationState>()
    return (
        (Array.isArray(referrer)
            ? referrer.includes(state?.referrer)
            : state?.referrer === referrer) && !!state?.from
    )
}
