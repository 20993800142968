import { gql } from '@thriveglobal/thrive-web-core'

export const UPDATE_LOCALE_TIMEZONE = gql`
    mutation updateTimezoneLocale(
        $locale: AllowedLocale!
        $timeZone: TimeZone!
    ) {
        identity {
            userAttributes {
                update(locale: $locale, timeZone: $timeZone) {
                    name
                    value
                }
            }
        }
    }
`
