import Keycloak from 'keycloak-js'
import {
    saveState,
    loadState,
    store,
    resetAuthState
} from '@thriveglobal/thrive-web-core'
import { REDIRECT_URI } from '../../protectedRoute'
import {
    updateStoreAndMaybeKeycloak,
    refreshKeycloakTokenInternal
} from './sharedKeycloakUtils'
import { AuthType } from '../../../../store/selectors/auth'

const isLocal = window.location.hostname == 'localhost'
// TODO move these to enum values
const IDP_HINT = 'okta'
const ADMIN_CLIENT = 'admin-ui'
const ADMIN_REALM = 'master'

// Factory to create a singleton Keycloak instance
function keycloakFactory() {
    let keycloak: Keycloak | null = null

    function createKeycloak() {
        return new Keycloak({
            url: process.env.KEYCLOAK_IDENTITY_PROVIDER,
            realm: ADMIN_REALM,
            clientId: ADMIN_CLIENT
        })
    }

    async function initKeycloak() {
        if (keycloak) {
            return keycloak
        }
        const authState = store.getState().auth
        const accessToken = authState.accessToken
        const idToken = authState.tokenId
        const refreshToken = authState.refreshToken
        keycloak = await createKeycloak()
        await keycloak.init({
            enableLogging: true,
            checkLoginIframe: false,
            ...(refreshToken && { refreshToken }),
            ...(accessToken && { token: accessToken }),
            ...(idToken && { idToken })
        })
        return keycloak
    }

    return {
        getKeycloak: () => initKeycloak(),
        _clearKeycloak: () => {
            keycloak = null
        }
    }
}

export const { getKeycloak, _clearKeycloak } = keycloakFactory()

export async function initiateKeycloakLogin(keycloak: Keycloak) {
    if (!keycloak.authenticated) {
        const originalUrl = window.location.href
        if (!originalUrl.includes('login/callback')) {
            saveState(REDIRECT_URI, originalUrl)
        }
        keycloak.login({
            idpHint: IDP_HINT,
            // Todo: Change this to a route similar to `login/callback`
            redirectUri: isLocal
                ? `http://localhost:3000/login/callback`
                : `${process.env.KEYCLOAK_REDIRECT_URL}/login/callback`
        })
    } else {
        await updateStoreAndMaybeKeycloak({
            keycloak,
            realm: ADMIN_REALM,
            accessToken: keycloak.token,
            refreshToken: keycloak.refreshToken,
            idToken: keycloak.idToken
        })
    }
}

async function keyCloakLogout(keycloak: Keycloak) {
    store.dispatch(resetAuthState())
    localStorage.clear()
    return keycloak.logout({
        redirectUri: window.location.href
    })
}

export async function signIn() {
    const keycloak = await getKeycloak()
    await initiateKeycloakLogin(keycloak)
}

export async function refreshToken() {
    let keycloak = null
    try {
        keycloak = await getKeycloak()
    } catch (err) {
        console.error('refreshToken error', err)
    }
    await refreshKeycloakTokenInternal(keycloak)
}

export async function signOut() {
    const keycloak = await getKeycloak()
    await keyCloakLogout(keycloak)
}

export async function redirect() {
    const keycloak = await getKeycloak()
    if (keycloak.authenticated) {
        await updateStoreAndMaybeKeycloak({
            keycloak,
            realm: ADMIN_REALM,
            accessToken: keycloak.token,
            refreshToken: keycloak.refreshToken,
            idToken: keycloak.idToken
        })
        const redirectUrl = loadState(REDIRECT_URI) || '/'
        window.location.href = redirectUrl
    }
}

export async function configure() {}

export function createIdentityInternalAdapter() {
    return {
        configure,
        signOut,
        signIn,
        refreshToken,
        redirect,
        type: AuthType.identity_internal
    }
}
