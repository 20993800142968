import { UserState } from '@thriveglobal/thrive-web-core'

export function selectUsersFirstName(userState: UserState): string {
    return userState.firstName
}

export function selectUsersLastName(userState: UserState): string {
    return userState.lastName
}

export function selectUsersFullName(userState: UserState): string {
    return userState.fullName
}

export function selectUsersId(userState: UserState): string {
    return userState.userId
}

export function selectUsersEmail(userState: UserState): string {
    return userState.email
}

export function selectUsersCompanyId(userState: UserState): string {
    return userState.companyId
}

export function selectUsersProfileImageUrl(userState: UserState): string {
    return userState.userProfileImageUrl
}
